<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoaded"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">{{ headerText }}</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row ma-0">
          <div v-if="!isClient" class="col-12 col-sm-6 my-0 py-0">
            <v-autocomplete
              v-model="formData.customer_id"
              label="Customer"
              :items="serverData.customers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="customerIdErrors"
              @blur="$v.formData.customer_id.$touch()"
            />
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-select
              v-model="formData.type"
              label="Type"
              :items="serverData.types"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
              :error-messages="typeErrors"
              @blur="$v.formData.type.$touch()"
            />
          </div>
          <div
            class="col-12 col-sm-6 my-0 py-0"
            v-if="
              formData.type === 'shipper' || formData.type === 'return_address'
            "
          >
            <v-select
              v-model="formData.is_default_shipper_address"
              label="Default address"
              :items="[
                { index: 0, text: 'No' },
                { index: 1, text: 'Yes' },
              ]"
              item-text="text"
              item-value="index"
              dense
              outlined
            />
          </div>
          <div class="col-12 my-0 py-0" :class="{ 'col-sm-6': !isClient }">
            <v-text-field
              class
              v-model="formData.title"
              label="Title"
              clearable
              outlined
              dense
              :error-messages="titleErrors"
              @input="$v.formData.title.$touch()"
              @blur="$v.formData.title.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              class
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              class
              v-model="formData.surname"
              label="Surname"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <VueTelInputVuetify
              class="ma-0 pa-0 mx-1"
              outlined
              v-model="formData.phone"
              :inputOptions="{ showDialCode: false, tabindex: 0 }"
              mode="international"
              dense
              disabledFetchingCountry
              defaultCountry="AE"
              :error-messages="phoneErrors"
              @input="$v.formData.phone.$touch()"
              @blur="$v.formData.phone.$touch()"
            ></VueTelInputVuetify>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.email"
              label="Email"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12 col-sm-6 col-md-3 my-0 py-0">
            <v-autocomplete
              @change="onCollectionCountryChange"
              v-model="formData.country_id"
              label="Country"
              item-text="text"
              item-value="index"
              :items="collectionAddressServerData.countries"
              clearable
              outlined
              dense
              :error-messages="collectionCountryErrors"
              @input="$v.formData.country_id.$touch()"
              @blur="$v.formData.country_id.$touch()"
            ></v-autocomplete>
          </div>

          <div class="col-12 col-sm-6 col-md-3 my-0 py-0">
            <v-combobox
              v-model="formData.state"
              :search-input.sync="collection_state_text"
              :items="collectionAddressServerData.states"
              item-text="title"
              item-value="id"
              label="State"
              persistent-hint
              outlined
              dense
              :error-messages="collectionStateErrors"
              @input="$v.formData.state.$touch()"
              @blur="$v.formData.state.$touch()"
              @change="onCollectionStateChange"
              @keyup="updateValue('collection_state')"
              :disabled="isCollectionStatesLoaded"
              :rules="[validationValueCheck('collection_state')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{
                        collection_state_text
                      }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'state',
                            formData.country_id,
                            onCollectionCountryChange,
                            collection_state_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <!-- row 2 end -->

          <div class="col-12 col-sm-6 col-md-3 my-0 py-0">
            <v-combobox
              v-model="formData.city"
              :search-input.sync="collection_city_text"
              :items="collectionAddressServerData.cities"
              item-text="title"
              item-value="id"
              label="City"
              persistent-hint
              outlined
              dense
              :error-messages="collectionCityErrors"
              @input="$v.formData.city.$touch()"
              @blur="$v.formData.city.$touch()"
              @change="onCollectionCityChange"
              @keyup="updateValue('collection_city')"
              :disabled="isCollectionCitiesLoaded"
              :rules="[validationValueCheck('collection_city')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{
                        collection_city_text
                      }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'city',
                            formData.state.id,
                            onCollectionStateChange,
                            collection_city_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-sm-6 col-md-3 my-0 py-0">
            <v-combobox
              v-model="formData.area"
              :search-input.sync="collection_area_text"
              :items="collectionAddressServerData.areas"
              item-text="title"
              item-value="id"
              label="Area"
              persistent-hint
              outlined
              dense
              @keyup="updateValue('collection_area')"
              :disabled="isCollectionAreasLoaded"
              :rules="[validationValueCheck('collection_area')]"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{
                        collection_area_text
                      }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'area',
                            formData.city.id,
                            onCollectionCityChange,
                            collection_area_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>

          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="$v.formData.address.$model"
              label="Address 1"
              clearable
              outlined
              dense
              :error-messages="addressErrors"
              @input="$v.formData.address.$touch()"
              @blur="$v.formData.address.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.address2"
              label="Address 2"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.postal_code"
              label="Postal code"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6 my-0 py-0">
            <v-text-field
              v-model="formData.note"
              label="Note"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
        </form>

        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        ></CreateLocation>
        <!--begin::Actions-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetFormData"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            {{ submitButtonText }}
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swalEdited from "@/core/services/AlertServices/SwalEdited";
import swalAdded from "@/core/services/AlertServices/SwalAdded";
import CreateLocation from "@/own/components/users/sellerAddresses/CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

export default {
  name: "ActionsDialog",
  props: ["refresher", "pageLoader"],
  mixins: [validationMixin],
  components: { VueTelInputVuetify, CreateLocation },
  validations() {
    return {
      formData: {
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        title: { required },
        name: { required },
        phone: { required },
        country_id: { required },
        state: { required },
        city: { required },
        address: { required },
        type: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    serverData: null,
    collectionAddressServerData: {},

    collection_state_text: null,
    collection_city_text: null,
    collection_area_text: null,

    location_type: null,
    locaiton_id: null,
    location_function: null,

    location_initValue: "", // before was null

    formData: {
      customer_id: null, //requiredIF...
      title: null, //required
      name: null, //required
      surname: null,
      phone: null, //required
      email: null,
      address: null, //required
      address_2: null,
      postal_code: null,
      note: null,
      country_id: null,
      state: null,
      city: null,
      area: null,
      type: null,
      is_default_shipper_address: 0,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetFormData();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    resetFormData() {
      this.serverData = null;
      this.collectionAddressServerData = {};

      this.collection_state_text = null;
      this.collection_city_text = null;
      this.collection_area_text = null;
      this.location_type = null;
      this.locaiton_id = null;
      this.location_function = null;
      this.location_initValue = "";

      this.formData = {
        customer_id: null,
        title: null,
        name: null,
        surname: null,
        phone: null,
        email: null,
        address: null,
        address_2: null,
        postal_code: null,
        note: null,
        country_id: null,
        state: null,
        city: null,
        area: null,
        type: null,
        is_default_shipper_address: 0,
      };
    },
    loadDataFromServer() {
      // this.$store.dispatch(GET_DATA);
      this.pageLoader(true);
      if (this.actionType) {
        let sendData = { id: this.$store.getters.getItemForAction.id };
        ApiService.post("/authorizations/seller_addresses/edit", sendData)
          .then(({ data }) => {
            // console.log(data);
            this.collectionAddressServerData = {
              countries: data.countries,
              states: data.states,
              cities: data.cities,
              areas: [...data.areas],
            };
            setTimeout(() => {
              this.formData = {
                id: data.address.id,
                customer_id: data.address.customer_id,
                title: data.address.title,
                name: data.address.name,
                surname: data.address.surname,
                phone: data.address.phone,
                email: data.address.email,
                // company: data.address.company,
                address: data.address.address,
                address_2: data.address.address_2,
                postal_code: data.address.postal_code,
                note: data.address.note,
                country_id: data.address.country_id,
                type: data.address.type,
                is_default_shipper_address:
                  data.address.is_default_shipper_address,
              };
              // this.formData.country_id = data.address.country_id;
              this.collection_state_text = data.address.state_id?.toString();
              this.collection_city_text = data.address.city_id?.toString();
              this.collection_area_text = data.address.area_id?.toString();

              this.formData.state = data.states.find(
                (item) => item.id === data.address.state_id
              );
              this.formData.city = data.cities.find(
                (item) => item.id === data.address.city_id
              );
              this.formData.area = data.areas.find(
                (item) => item.id === data.address.area_id
              );

              this.serverData = data;

              this.pageLoader(false);
            }, 300);
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      } else {
        ApiService.post("/authorizations/seller_addresses/create")
          .then(({ data }) => {
            // console.log([...data.areas]);
            if (data.success) {
              this.collectionAddressServerData = {
                countries: data.countries,
                states: data.states,
                cities: data.cities,
                areas: [...data.areas],
              };
              setTimeout(() => {
                this.formData.country_id = data.default_country_id;
                this.collection_state_text = data.default_state_id.toString();
                this.collection_city_text = data.default_city_id.toString();

                this.formData.state = data.states.find(
                  (item) => item.id === data.default_state_id
                );
                this.formData.city = data.cities.find(
                  (item) => item.id === data.default_city_id
                );

                this.serverData = data;

                this.pageLoader(false);
              }, 300);
            }
          })
          .catch(() => {
            this.dialog = false;
            this.pageLoader(false);
          });
      }
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "collection_state": {
          const item = this.collectionAddressServerData.states.find(
            (state) => state.title === this.collection_state_text
          );

          if (!item) {
            this.onCollectionStateChange(item);
          }

          break;
        }

        case "collection_city": {
          const item = this.collectionAddressServerData.cities.find(
            (city) => city.title === this.collection_city_text
          );

          if (!item) {
            this.onCollectionCityChange(item);
          }
          break;
        }

        case "collection_area": {
          const item = this.collectionAddressServerData.areas.find(
            (area) => area.title === this.collection_area_text
          );

          if (!item) {
            this.formData.area = item.title;
          }
          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "collection_state":
          try {
            if (
              !this.collectionAddressServerData.states.find(
                (state) => state.title === this.collection_state_text
              )
            ) {
              validation = "Please, select valid state!";
            }
          } catch {
            break;
          }

          break;

        case "collection_city":
          try {
            if (
              !this.collectionAddressServerData.cities.find(
                (city) => city.title === this.collection_city_text
              )
            ) {
              validation = "Please, select valid city!";
            }
          } catch {
            break;
          }

          break;

        case "collection_area":
          try {
            if (!this.collection_area_text) {
              validation = true;
              break;
            } else if (
              !this.collectionAddressServerData.areas.find(
                (area) => area.title === this.collection_area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "collection_state":
          this.collection_state_text = value;

          if (value !== "") {
            this.formData.state = this.collectionAddressServerData.states.find(
              (state) => state.title === this.collection_state_text
            );
            await this.onCollectionStateChange(this.collection_state_text);
          }

          break;

        case "collection_city":
          this.collection_city_text = value;

          if (value !== "") {
            this.formData.city = this.collectionAddressServerData.cities.find(
              (city) => city.title === this.collection_city_text
            );
            await this.onCollectionCityChange(this.collection_city_text);
          }
          break;

        case "collection_area":
          this.collection_area_text = value;
          break;
      }
    },
    async onCollectionCountryChange(val, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.collectionAddressServerData.states = response.data.states;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    async onCollectionStateChange(val, is_id = false) {
      // console.log("onCollectionStateChange", val);
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.collectionAddressServerData.states.find(
              (state) => state.title === val.title
            ).id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            // this.serverCities = response.data.cities;
            this.collectionAddressServerData.cities = response.data.cities;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };

            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },

    async onCollectionCityChange(val, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.collectionAddressServerData.cities.find(
              (city) => city.title === val.title
            ).id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }

        this.pageLoader(true);
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.collectionAddressServerData.areas = response.data.areas;

            this.collectionAddressServerData = {
              ...this.collectionAddressServerData,
            };
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    createNewItem(type, id, func, value = "") {
      // console.log(type, id, func);
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    handleFormValidation(fieldName) {
      const errors = [];
      // if (!this.$v.formData[fieldName].$dirty) return errors;
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("numeric" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      return errors;
    },
    submitForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let sendData = {
        customer_id: this.formData.customer_id,
        title: this.formData.title,
        name: this.formData.name,
        surname: this.formData.surname,
        phone: this.formData.phone,
        email: this.formData.email,
        address: this.formData.address,
        address_2: this.formData.address_2,
        postal_code: this.formData.postal_code,
        note: this.formData.note,
        country_id: this.formData.country_id,
        type: this.formData.type,
        is_default_shipper_address: this.formData.is_default_shipper_address,
      };
      sendData.state_id = this.formData.state.id;
      sendData.city_id = this.formData.city.id;
      if (this.formData.area) {
        sendData.area_id = this.formData.area.id;
      }

      if (this.actionType) {
        sendData.id = this.$store.getters.getItemForAction.id;
      }
      // console.log("sendData", sendData);
      let actionUrl = "store";
      if (this.actionType) actionUrl = "update";
      ApiService.post(`/authorizations/seller_addresses/${actionUrl}`, sendData)
        .then(() => {
          if (this.actionType) swalEdited();
          else swalAdded();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
  },
  computed: {
    // if true, we are editing item, false we are adding
    actionType: function () {
      return !!this.$store.getters.getItemForAction;
    },
    submitButtonText: function () {
      if (this.actionType) return "Update";
      else return "Submit";
    },
    headerText: function () {
      if (this.actionType) return "Edit Item";
      else return "Add New Item";
    },
    isClient: function () {
      return this.$store.getters.isClient;
      // return true;
    },
    customerIdErrors: function () {
      return this.handleFormValidation("customer_id");
    },
    nameErrors: function () {
      return this.handleFormValidation("name");
    },
    isDataLoaded() {
      return !!this.serverData;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    titleErrors() {
      return this.handleFormValidation("title");
    },
    phoneErrors() {
      return this.handleFormValidation("phone");
    },

    isCollectionStatesLoaded: function () {
      return !this.collectionAddressServerData.states;
    },

    isCollectionCitiesLoaded: function () {
      return !this.collectionAddressServerData.cities;
    },

    isCollectionAreasLoaded: function () {
      return !this.collectionAddressServerData.areas;
    },

    collectionStateErrors: function () {
      return this.handleFormValidation("state");
    },
    collectionCityErrors: function () {
      return this.handleFormValidation("city");
    },
    collectionCountryErrors: function () {
      return this.handleFormValidation("country_id");
    },
    addressErrors() {
      return this.handleFormValidation("address", this);
    },
    typeErrors() {
      return this.handleFormValidation("type", this);
    },
  },
};
</script>
